// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/light-pattern.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.light-pattern[data-v-38e5737f] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: repeat;\n  background-size: 55px;\n}\n/* .action-button {\n  display: none;\n} */\n/* @media (min-width: 1024px) and (max-width: 2560px) {\n  .action-button {\n    display: block;\n  }\n}\n@media (max-width: 280px) {\n  .action-button {\n    display: block;\n  }\n} */\n.whatsapp-section[data-v-38e5737f] {\n  height: 70px;\n  width: 70px;\n  z-index: 4;\n  position: fixed;\n  bottom: 60px;\n  left: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
