// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/Play-Exchange-Icons.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/Play-Exchange-Icons.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/Play-Exchange-Icons.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/Play-Exchange-Icons.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#Play-Exchange-Icons" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Generated by Glyphter (http://www.glyphter.com) on  Thu Sep 16 2021*/\n@font-face {\n    font-family: 'Play Exchange Icons';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('embedded-opentype'),\n         url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff'),\n         url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('truetype'),\n         url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('svg');\n    font-weight: normal;\n    font-style: normal;\n}\n[class*='iconpe-']:before{\n\tdisplay: inline-block;\n   font-family: 'Play Exchange Icons';\n   font-style: normal;\n   font-weight: normal;\n   line-height: 1;\n   -webkit-font-smoothing: antialiased;\n   -moz-osx-font-smoothing: grayscale\n}\n.iconpe-live-cards:before{content:'\\0041';}\n.iconpe-live-casino:before{content:'\\0042';}\n.iconpe-sportsbook:before{content:'\\0043';}\n.iconpe-transfer-statement:before{content:'\\0044';}\n.iconpe-time-settings:before{content:'\\0045';}\n.iconpe-rules-regulations:before{content:'\\0046';}\n.iconpe-profit-loss:before{content:'\\0047';}\n.iconpe-cricket:before{content:'\\0048';}\n.iconpe-horse-racing:before{content:'\\0049';}\n.iconpe-tennis:before{content:'\\004a';}\n.iconpe-grey-hound:before{content:'\\004b';}\n.iconpe-Hamburger:before{content:'\\004c';}\n.iconpe-slot:before{content:'\\004d';}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
